import { translate } from '@/core/localization/translate';
export const {
  selectedProductTitle,
  suggestedProductTitle,
  promotedProductTitle,
  recentlyTitle,
  ariaLabelPrevious,
  ariaLabelNext
} = translate({
  "selectedProductTitle": "Unsere Top-Empfehlungen",
  "suggestedProductTitle": "Unsere Bestseller",
  "promotedProductTitle": "Fragen Sie nach unseren Angeboten!",
  "recentlyTitle": "Zuletzt angesehen",
  "ariaLabelPrevious": "Bisherige",
  "ariaLabelNext": "N\xE4chster"
});